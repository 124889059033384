import React, { FC, useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { LoginCallback, SecureRoute } from '@okta/okta-react';
import ReactGA from 'react-ga';
import RoutePaths from './Helpers/route-constants';
import AuthContainer from './AuthApp';

const AppRoutes: FC<{}> = (): JSX.Element => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
    // ReactGA.pageview({ hitType: 'pageview', page: location.pathname + location.search }); // For GA4 with hitType
  }, [location]);

  return (
    <Switch>
      <SecureRoute
        exact
        path={RoutePaths.Dashboard}
        component={AuthContainer}
      />
      <Route path='/implicit/callback' component={LoginCallback} />
    </Switch>
  );
};

export default AppRoutes;
